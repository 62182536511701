import './src/styles/global.css'
import React from 'react';
import Layout from './src/components/layout';
import Provider from './provider';



export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

// export function wrapPageElement({ element, props }) {
//     return <Layout {...props}>{element}</Layout>  
// }

export const wrapRootElement = Provider;