import * as React from 'react'

const PathBar = () => {
    return (
        // <div 
        // className="
        //     h-1 w-full 
        //     bg-skin-primary
        //     rounded-lg
        //     my-2
        // "
        // >
        // </div>
        <>
            {/* <div className="flex justify-between px-1">
                <span className="text-base text-sm text-skin-primary">Beginner</span>
                <span className="text-sm text-skin-primary">45%</span>
            </div> */}
            <div className="w-full bg-gray-200 rounded-full h-0.5">
                <div className="bg-skin-primary h-0.5 rounded-full" style={{ width: '45%' }}></div>
            </div>
        </>
    )
}

export default PathBar