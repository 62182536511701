export const isBrowser = () => typeof window !== "undefined"

export const getItem = (key) =>
  isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : ""

export const setItem = (key, value) =>
  window.localStorage.setItem(key, value)

  export const removeItem = (key) =>
  window.localStorage.removeItem(key)


export const isLoggedIn = () => {
  const token = getItem("token")
  if(token == "" || token == null){
    return false;
  }
  return true;
}

export const logout = callback => {
  removeItem("user")
  removeItem("token")
  callback()
}