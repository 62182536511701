import React, { useState } from 'react';

const defaultState = {
    isDark: () => {},
    nav: () => {},
    login: () => {},
    isLogin: () => {},
    authenticated: false,
    setAuthenticated: (auth) => {}
  }

export const myContext = React.createContext(defaultState);

const Provider = props => {
    const [isDark, setTheme ] = useState(false);
    const [ nav, showNav ] = useState(false);
    const [login, showLogin ] = useState(false);
    const [isLogin, doLogin ] = useState(false);

    

    return (
        <myContext.Provider value={{ 
            isDark, changeTheme: () => setTheme(!isDark),
            nav, changeNav: () => showNav(!nav),
            login, changeLogin: () => showLogin(!login),
            isLogin, changeIsLoggin: () => doLogin(!isLogin)
         }}>
            {props.children}
        </myContext.Provider>
    )
}

export default ({ element }) => (
    <Provider>
        {element}
    </Provider>
);